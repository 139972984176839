import { Form, Formik, useFormik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { BlackLayer, Button, Container } from "../GobalStyle";
import { BookADemoBack, ContolioLogo } from "../Utils/Images";
import * as Yup from "yup";
import InputField from "../Validation/InputField";
import DescriptionField from "../Validation/DescriptionField";
import { LiveBaseURL } from "../Utils/BaseURL";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function BookADemo() {
  const [loading, setLoading] = useState(false);
  const ButtonStyle = {
    background: "#fff",
    color: "#145da0",
    margin: "10px 0 0 0",
  };

  const formik = useFormik({
    initialValues: {
      FullName: "",
      Email: "",
      Contact: "",
      Description: "",
    },
  });

  const navigate = useNavigate();

  const SignupSchema = Yup.object().shape({
    FullName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    Email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),

    Contact: Yup.string()
      .min(8, "Too Short!")
      .max(14, "Too Long!")
      .required("Required"),

    Description: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await fetch(`${LiveBaseURL}api/v1/contact_demo_query`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullname: values.FullName,
          email: values.Email,
          phone: values.Contact,
          description: values.Description,
        }),
      });
      setLoading(false);
      toast.info(
        "We have received your query and we will get in touch with you as soon as possible",
        { theme: "colored" }
      );
      navigate("/");
    } catch (e) {
      console.log(e);
      toast.error(e, { theme: "colored" });
      setLoading(false);
    }
  };

  return (
    <div>
      <BookADemoWrapper>
        <BlackLayer style={{ minHeight: "100%" }} />
        <Container>
          <BookADemoBoxWrap>
            <BookADemoBox>
              <img src={ContolioLogo} alt="logo" />
              <div className="w-100 m-2">
                <Formik
                  initialValues={formik}
                  validationSchema={SignupSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  <Form>
                    <InputField name="FullName" placeholder="Full Name" />
                    <InputGridX2>
                      <InputField name="Email" placeholder="Email" />

                      <InputField name="Contact" placeholder="Contact" />
                    </InputGridX2>
                    <DescriptionField
                      name="Description"
                      placeholder="Description"
                    />
                    <Button type="submit" style={ButtonStyle}>
                      {!loading ? "Submit" : <Spinner animation="grow" />}
                    </Button>
                  </Form>
                </Formik>
              </div>
            </BookADemoBox>
          </BookADemoBoxWrap>
        </Container>
      </BookADemoWrapper>
    </div>
  );
}

const InputGridX2 = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const BookADemoBoxWrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 160px 0 40px 0;
`;

const BookADemoBox = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(313deg, #26346e 0%, #000000c7 );
  position: relative;
  z-index: 2;
  padding: 30px 20px;
  border-radius: 6px;

  img {
    width: 120px;
  }
`;

const BookADemoWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-image: url(${BookADemoBack});
  background-size: cover;
`;
