import Background from "../Assets/contolioBackground.png";
import ContolioLogo from "../Assets/TopLOGO.svg";
import ContolioLogo1 from "../Assets/TopLOGO1.svg";
import HomeSecondImage from "../Assets/SecondLayoutImage.png";
import Silder from "../Assets/slider1.png";
import Silder1 from "../Assets/slider2.png";

import iphone from "../Assets/iphone.png";
import googleplay from "../Assets/googleplay.png";
import applestore from "../Assets/applestore.png";
import FooterImage from "../Assets/FooterContolio.png";
import PricingBackground from "../Assets/pricingBack.png";
import TickIcon from "../Assets/tickIcon.svg";
import BookADemoBack from "../Assets/BookADemo.png";

export {
  Background,
  ContolioLogo,
  ContolioLogo1,
  HomeSecondImage,
  Silder,
  Silder1,
  applestore,
  googleplay,
  iphone,
  FooterImage,
  PricingBackground,
  TickIcon,
  BookADemoBack,
};
