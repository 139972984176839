import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BlackLayer, Container, PricingWrapper } from "../GobalStyle";
import { LiveBaseURL } from "../Utils/BaseURL";

export default function Comparison() {
  const [data, setData] = useState([]);
  const getDetails = async () => {
    try {
      await fetch(`${LiveBaseURL}api/v1/comparison_data`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div>
      <PricingWrapper>
        <BlackLayer />
        <Container>
          <ComparisonContainer>
            
            {data ? data?.map((item) => (
              <ComparisonBox>
                <h4>Service</h4>
                <p style={{ fontWeight: "700" }}>{item?.service}</p>
                <h4>Without Contolio</h4>
                <ul>
                  {item?.without_contolio.map((ele) => (
                    <li>{ele}</li>
                  ))}
                </ul>
                <h4>With Contolio</h4>

                <ul style={{ color: "#222" }}>
                  {item?.with_contolio.map((ele) => (
                    <>{ele !== "" && <li>{ele}</li>}</>
                  ))}
                </ul>
              </ComparisonBox>
            ))
          :
          <NoDataFound>NO DATA FOUND</NoDataFound>
          }
          </ComparisonContainer>
        </Container>
      </PricingWrapper>
    </div>
  );
}

const NoDataFound = styled.div`

width:90%;
height:300px;
background:#ffffffba;
display: flex;
align-items: center;
justify-content: center;
margin: 0 auto;
border-radius:10px;
font-size:42px;
font-weight:400;
font-family: "Montserrat", sans-serif;
    font-style: normal;


`

const ComparisonContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  padding: 200px 0 40px 0;
`;

const ComparisonBox = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(61, 107, 192, 0.28);
  padding: 20px 30px;
  font-family: "Montserrat";
  font-style: normal;
  color: #145da0;
  margin: 40px 0 0 0;

  h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    color: #000000;
    opacity: 0.6;
  }
  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: justify;
    opacity: 0.7;
  }
`;
